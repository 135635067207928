.bg-image-vertical {
    position: relative;
    overflow: hidden;
    background-repeat: no-repeat;
    background-position: right center;
    background-size: auto 100%;
}

.form-control:focus {
    border-color: none !important;
    outline: 0;
    box-shadow: none !important;
}

.login-main {
    background-image: url('../assets/image/career-bg.png');
    height: 100vh;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-attachment: fixed;
}

.login-sub-sec {
    display: flex;
    height: 100vh;
    width: 100%;
    justify-content: center;
    background: linear-gradient(268.86deg, rgba(0, 0, 0, 0.6) 40.2%, rgba(0, 0, 0, 0.6) 114.1%);
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.login-card {
    width: 65% !important;
    background: #ffffff14 !important;
}

.login-title-sec h1 {
    padding: 0rem 10rem;
}

@media (min-width: 1025px) {
    .h-custom-2 {
        height: 100%;
    }
}

@media (max-width: 1000px) {
    .login-sub-sec {
        margin: 0 !important;
    }
    .login-card {
        width: 100% !important;
    }
    .login-title-sec h1 {
        padding: 0 !important;
        font-size: 24px !important;
    }
}