@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;300&display=swap');
.nav-link {
    font-size: 17px !important;
    font-family: 'Poppins', sans-serif;
}

.login-link {
    color: rgb(73, 73, 73) !important;
    margin-right: 20px;
    font-weight: 600;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
}


/* .login-link:active,
.login-link:hover {
    color: rgb(0 50 189) !important;
} */

.login-nav.active a {
    color: rgb(0 50 189) !important;
}

.navbar-main {
    position: sticky !important;
    background: white !important;
    font-family: 'Poppins', sans-serif !important;
    z-index: 99 !important;
    top: 0 !important;
    box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.5);
}

.dropdown-item.active,
.dropdown-item:active {
    background-color: azure !important;
}

.contact-btn {
    background: rgb(0 50 189) !important;
    border: 1px solid rgb(0 50 189) !important;
    color: white !important;
    border-radius: 5px !important;
}

.navbar-toggler {
    border: none !important;
}

.navbar-toggler:focus {
    box-shadow: none !important;
}

@media (min-width:567px) {
    .nav-link {
        color: black !important;
    }
    .nav-link a {
        color: black !important;
        text-decoration: none;
    }
}

@media (max-width:567px) {
    .home_btn {
        margin-top: 25px;
    }
    /* .nav-link a{
        color:white !important;
        text-decoration: none;
        }
        .nav-link {
            color:white !important;
            } */
}


/* .bg_Color {
    box-shadow: 13px 13px 20px #cbced1, -13px -13px 20px #ffffff;
} */

@media (min-width:1000px) {
    .navbar-light {
        height: 80px;
    }
}